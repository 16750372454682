
import { Component, Vue } from 'vue-property-decorator';
import { Activity, ActivityType, ProgramFormula, Phase, Program, ProgramType } from "@/types";
import { ROOT_ACTIONS } from "@/store/actions";
import { programTypes } from '@/variables'

import { firestore } from '@/firebase';
import { Timestamp, addDoc, collection } from 'firebase/firestore';

@Component
export default class NewTemplate extends Vue {

  PHASE_COUNT = 3;

  // TODO: move this globally
  activityTypes = [
    {
      text: 'Individuele begeleiding',
      value: ActivityType.INDIVIDUAL_COACHING,
    },
    {
      text: 'Assesment',
      value: ActivityType.ASSESMENT
    },
    {
      text: 'Begeleiding',
      value: ActivityType.CLASSIC_TRAINING
    },
    {
      text: 'Webinar',
      type: ActivityType.WEBINAR,
    },
    {
      text: 'Workshop',
      type: ActivityType.WORKSHOP
    }
  ]

  _programTypes = programTypes;

  newPhases: Phase[] = [];
  activities: Activity[] = [];

  getHoursForPhase(phase: Phase): number {
    const hours = this.activities.filter(e => e.phaseId === phase.id).map(e => e.plannedHours).reduce((a, b) => a! + b!, 0);
    if (isNaN(hours as number)) return 0;
    return hours as number;
  }

  inputs = {
    title: "",
    description: "",
    isOnline: false,
    required: false,
    type: ProgramType.GROUP,
    language: 'nl-be'
  };

  loading = {
    submit: false,
  };

  headers = [
    { text: "Titel", value: "title" },
    { text: "Type", value: "type" },
  ];

  async mounted() {
    this.fillEmptyPhases();
  }

  validateInputs(): boolean {
    if (this.inputs.title == '') return false;
    return true;
  }

  async submit() {
    try {
      if (!this.validateInputs()) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'warning',
          text: 'Ongeldige invoer'
        })
        return;
      }
      this.loading.submit = true;

      this.newPhases = this.newPhases.filter(e => e.title.length > 0);

      this.newPhases.forEach(phase => {
        phase.hours = this.getHoursForPhase(phase);
      })

      const newProgram = await addDoc(collection(firestore, 'program-templates'), {
        title: this.inputs.title,
        type: this.inputs.type
      })

      for (let [index, phase] of this.newPhases.entries()) {
        const tempPhaseId = phase.id;
        const createdPhase = await addDoc(collection(firestore, `phases`), {
          title: phase.title,
          description: phase.description,
          hours: phase.hours,
          programId: newProgram.id,
          index
        })

        for (let activity of this.activities.filter(e => e.phaseId === tempPhaseId)) {
          await addDoc(collection(firestore, `activities`), {
            phaseId: createdPhase.id,
            name: activity.name,
            description: activity.description,
            plannedHours: activity.plannedHours,
            type: activity.type,
          })
        }
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: "Template aangemaakt",
      });

      this.$router.push('/admin/presence/templates')
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.submit = false;
    }
  }

  fillEmptyPhases() {
    for (let i = 0; i < this.PHASE_COUNT; i++) {
      this.newPhases.push({
        id: `temp-${i}`,
        description: "",
        title: "",
        programId: '',
        start: new Timestamp(0, 0),
        end: new Timestamp(0, 0),
        hours: 0,
        index: i
      });
    }
  }

  addNewActivityToPhase(phaseId?: string) {
    const p = this.newPhases.find(e => e.id === phaseId)
    if (!p || !p.id) return;
    this.activities.push({
      name: "",
      description: "",
      type: ActivityType.INDIVIDUAL_COACHING,
      isOnline: false,
      plannedHours: 0,
      end: null,
      start: new Date(),
      required: false,
      phaseId: p.id,
    });
  }

  removeActivityFromPhase(phaseId: string | undefined, activityIndex: number) {
    this.activities.filter(e => e.phaseId === phaseId).splice(activityIndex, 1)
  }

  languages = [
    {
      text: 'Nederlands',
      value: 'nl-be'
    },
    {
      text: 'Frans',
      value: 'fr'
    }
  ]
} 
